import {
  Heading,
  Text,
  Stack,
  SimpleGrid,
  Box,
  Avatar,
  Center,
  Badge,
} from "@chakra-ui/react";

export function WhoWeAre() {
  return (
    <>
      <Heading
        className="history-heading"
        marginLeft={{ base: "5%", md: "20%" }}
        marginRight={{ base: "5%", md: "20%" }}
        marginBottom={{ base: "15vw", md: "5vw" }}
        fontSize={{ base: "8vh", md: "10vh" }}
        lineHeight={{ base: "80%", md: "100%" }}
      >
        Quiénes somos?
      </Heading>
      <Box>
        <SimpleGrid
          columns={{ base: 1, md: 2 }}
          marginBottom={{ base: "5%", md: "5%" }}
          marginLeft={{ base: "5%", md: "20%" }}
          marginRight={{ base: "5%", md: "20%" }}
        >
          <Stack>
            <a
              href="https://twitter.com/raulcotrina"
              target={"_blank"}
              rel="noreferrer"
            >
              <Center py={6}>
                <Box
                  minH={"450px"}
                  className="card-zoomer"
                  width={{ base: "full", md: "95%" }}
                  boxShadow={"2xl"}
                  rounded={"lg"}
                  p={6}
                  m={2}
                  textAlign={"center"}
                >
                  <Avatar
                    size={"2xl"}
                    src="/raul-cotrina.jpeg"
                    alt="Raúl Cotrina"
                    mb={4}
                    mt={8}
                    pos={"relative"}
                  />
                  <Heading fontSize={"2xl"} fontFamily={"body"} color="#252526">
                    Raúl Cotrina
                  </Heading>
                  <Text fontWeight={600} color={"gray.500"} mb={4}>
                    @raulcotrina
                  </Text>
                  <Text textAlign={"center"} px={3} color="#252526">
                    Tras 15 años programando, Raúl Cotrina decidió que quería
                    aportar valor al sector que tanto ama desde otras
                    perspectivas. Lleva desde 2017 pivotando entre ventas,
                    recruitment y comunidad.
                  </Text>

                  <Stack
                    align={"center"}
                    justify={"center"}
                    direction={"row"}
                    mt={6}
                  >
                    <Badge px={2} py={1} fontWeight={"400"}>
                      #development
                    </Badge>
                    <Badge px={2} py={1} fontWeight={"400"}>
                      #consultancy
                    </Badge>
                  </Stack>
                </Box>
              </Center>
            </a>
          </Stack>
          <Stack>
            <a
              href="https://leonardopoza.com"
              target={"_blank"}
              rel="noreferrer"
            >
              <Center py={6}>
                <Box
                  minH={"450px"}
                  className="card-zoomer"
                  width={{ base: "full", md: "95%" }}
                  boxShadow={"2xl"}
                  rounded={"lg"}
                  p={6}
                  m={2}
                  textAlign={"center"}
                >
                  <Avatar
                    size={"2xl"}
                    src="/leonardo.poza.jpeg"
                    alt="Leonardo Poza"
                    mb={4}
                    mt={8}
                    pos={"relative"}
                  />
                  <Heading fontSize={"2xl"} fontFamily={"body"} color="#252526">
                    Leonardo Poza
                  </Heading>
                  <Text fontWeight={600} color={"gray.500"} mb={4}>
                    @leonardopoza
                  </Text>
                  <Text textAlign={"center"} px={3} color="#252526">
                    Leo es un programador con más de 15 años de experiencia y
                    —también— Technical Recruiter. Orgulloso burgalés, fan de
                    Gianluca Vacchi y pésimo jugador de tenis.
                  </Text>

                  <Stack
                    align={"center"}
                    justify={"center"}
                    direction={"row"}
                    mt={6}
                  >
                    <Badge px={2} py={1} fontWeight={"400"}>
                      #development
                    </Badge>
                    <Badge px={2} py={1} fontWeight={"400"}>
                      #consultancy
                    </Badge>
                  </Stack>
                </Box>
              </Center>
            </a>
          </Stack>
        </SimpleGrid>
      </Box>
    </>
  );
}
