import { Heading } from "@chakra-ui/react";
import ReactGA from "react-ga";
import { useEffect } from "react";

export function Header() {
  useEffect(() => {
    ReactGA.initialize("UA-XXXXXXXX");
  }, []);
  return (
    <>
      <Heading
        className="hero-heading"
        marginLeft={{ base: "5%", md: "19.7%" }}
        marginRight={{ base: "5%", md: "20%" }}
        marginTop={{ base: "10vh", md: "10vh" }}
        fontSize={{ base: "9vh", md: "20vh" }}
        lineHeight={{ base: "80%", md: "100%" }}
      >
        <span style={{ color: "#FFC43D" }}>dev</span>eloper <br></br>sessions
      </Heading>

      <Heading
        className="hero-skills"
        marginLeft={{ base: "5%", md: "20%" }}
        marginRight={{ base: "5%", md: "20%" }}
      >
        Queremos que Developer Sessions sea un espacio libre y distendido donde
        podamos charlar con personas del mundo IT sobre tecnología,
        desarrollo, creación y organización de equipos, cultura remota y muchas
        cosas más...
      </Heading>
    </>
  );
}
