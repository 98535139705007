import {
  Heading,
  Stack,
  SimpleGrid,
  Box,
  Center,
  Badge,
  Text,
} from "@chakra-ui/react";

export function DeveloperSessionsList() {
  return (
    <>
      <Heading
        className="history-heading"
        marginLeft={{ base: "5%", md: "20%" }}
        marginRight={{ base: "5%", md: "20%" }}
        marginBottom={{ base: "5%", md: "5%" }}
        marginTop={{ base: "20vw", md: "10vw" }}
        fontSize={{ base: "8vh", md: "10vh" }}
        lineHeight={{ base: "80%", md: "100%" }}
      >
        Sesiones pasadas
      </Heading>
      <Box>
        <SimpleGrid
          columns={{ base: 1, md: 3, lg: 3 }}
          marginTop={{ base: "20vw", md: "5vw" }}
          marginBottom={{ base: "30vw", md: "5vw" }}
          marginLeft={{ base: "5%", md: "20%" }}
          marginRight={{ base: "5%", md: "20%" }}
        >
           <Stack m={2}>
            <a
              href="https://twitter.com/i/spaces/1ynJOaAXzzQKR"
              target={"_blank"}
              rel="noreferrer"
              className="card-zoomer-soft"
            >
              <Center>
                <Box
                  p={6}
                  w={"full"}
                  boxShadow={"2xl"}
                  rounded={"lg"}
                  pos={"relative"}
                  zIndex={1}
                  className="card"
                >
                  <Stack align={"center"}>
                    <Heading
                      align={"center"}
                      className="talk-title-next"
                      marginTop="40px !important"
                    >
                      Organización de equipos Tech
                    </Heading>
                    <Badge colorScheme={"gray"} fontSize="md" marginBottom={4}>
                      Episodio #2
                    </Badge>
                    <Badge
                      colorScheme={"purple"}
                      fontSize="md"
                      marginBottom={4}
                    >
                      Twitter Spaces
                    </Badge>
                    <Text
                      align={"center"}
                      color="#62676F"
                      marginTop="25px !important"
                    >
                    Tras una interesante sesión sobre cultura remota, la
                    conversación derivó a cómo deberían estar organizados
                    nuestros equipos de ingeniería. Así que hemos invitado a {" "}
                    <a
                      href="https://twitter.com/ender3_"
                      target={"_blank"}
                      rel="noreferrer"
                    >
                      Luis Ibáñez
                    </a>
                    ,{" "}
                    <a
                      href="https://twitter.com/nhan_bcn"
                      target={"_blank"}
                      rel="noreferrer"
                    >
                      Ana Carmona
                    </a>
                    ,{" "}
                    <a
                      href="https://twitter.com/vlcervera"
                      target={"_blank"}
                      rel="noreferrer"
                    >
                      Victor Cervera
                    </a>
                    , y{" "}
                    <a
                      href="https://twitter.com/jcesarperez"
                      target={"_blank"}
                      rel="noreferrer"
                    >
                      Julio César Pérez
                    </a>{" "}
                    para profundizar sobre este tema.
                    </Text>
                  </Stack>
                </Box>
              </Center>
            </a>
          </Stack>
          <Stack m={2}>
            <a
              href="https://twitter.com/i/spaces/1lPKqBwgrYPGb?s=20"
              target={"_blank"}
              rel="noreferrer"
              className="card-zoomer-soft"
            >
              <Center>
                <Box
                  p={6}
                  w={"full"}
                  boxShadow={"2xl"}
                  rounded={"lg"}
                  pos={"relative"}
                  zIndex={1}
                  className="card"
                >
                  <Stack align={"center"}>
                    <Heading
                      align={"center"}
                      className="talk-title-next"
                      marginTop="40px !important"
                    >
                      Hablando de cultura remota
                    </Heading>
                    <Badge colorScheme={"gray"} fontSize="md" marginBottom={4}>
                      Episodio #1
                    </Badge>
                    <Badge
                      colorScheme={"purple"}
                      fontSize="md"
                      marginBottom={4}
                    >
                      Twitter Spaces
                    </Badge>
                    <Text
                      align={"center"}
                      color="#62676F"
                      marginTop="25px !important"
                    >
                      ¿Están las organizaciones preparadas para trabajar de
                      manera remota? Hablamos de cultura remota con{" "}
                      <a
                        href="https://twitter.com/AnaGrcol"
                        target={"_blank"}
                        rel="noreferrer"
                      >
                        Ana Gárcol
                      </a>
                      ,{" "}
                      <a
                        href="https://twitter.com/ender3_"
                        target={"_blank"}
                        rel="noreferrer"
                      >
                        Luis Ibáñez
                      </a>
                      , y{" "}
                      <a
                        href="https://twitter.com/adominguezg_"
                        target={"_blank"}
                        rel="noreferrer"
                      >
                        Álvaro Domínguez
                      </a>
                      , que nos comparten sus opiniones de cómo han organizado
                      sus equipos y dinámicas para poder hacerlo realidad.
                    </Text>
                  </Stack>
                </Box>
              </Center>
            </a>
          </Stack>
        </SimpleGrid>
      </Box>
    </>
  );
}
