import {
  Box,
  Container,
  Heading,
  Stack,
  useColorModeValue,
} from "@chakra-ui/react";

export function Footer() {
  return (
    <Box 
    boxShadow="dark-lg"
    bg={"#0f172a"}
    color={useColorModeValue("white")}>
      <Container  bg={"#0f172a"}
        as={Stack}
        maxW={"6xl"}
        minH="150px"
        height={"150px"}
        py={4}
        direction={{ base: "column", md: "row" }}
        spacing={4}
        justify={{ base: "center", md: "space-between" }}
        align={{ base: "center", md: "center" }}
      >
        <Heading className="footer-heading">© 2023 Developer Sessions.</Heading>

       
      </Container>
    </Box>
  );
}
