import {
  Heading,
  Stack,
  SimpleGrid,
  Text,
  Badge,
  VStack,
  Center,
  Box,
} from "@chakra-ui/react";

export function NextEvents() {
  return (
    <>
      <SimpleGrid
        columns={{ base: 1, md: 2 }}
        spacing={{ base: 8, md: 10 }}
        marginBottom={{ base: "5vw", md: "3vw" }}
        marginLeft={{ base: "5%", md: "20%" }}
        marginRight={{ base: "5%", md: "20%" }}
      >
        <Stack spacing={4}>
          <VStack spacing={4} align="stretch">
            <Heading
              className="latest-post-heading"
              marginTop={{ base: "0vw", md: "3vw" }}
              fontSize={{ base: "8vh", md: "10vh" }}
              lineHeight={{ base: "80%", md: "100%" }}
            >
              Próximo <br></br>evento.
            </Heading>
            <Heading
              className="next-date"
              fontSize={{ base: "3vh", md: "4vh" }}
            >
              Lunes, 27 noviembre - 18:30 
            </Heading>
          </VStack>
        </Stack>
        <VStack spacing={4} align="stretch">
          <Stack m={2}>
            <a
              href="https://twitter.com/i/spaces/1LyGBnVMqEEGN"
              target={"_blank"}
              rel="noreferrer"
              className="card-zoomer-soft"
            >
              <Center>
                <Box
                  p={6}
                  w={"full"}
                  boxShadow={"2xl"}
                  rounded={"lg"}
                  pos={"relative"}
                  zIndex={1}
                  className="card"
                >
                  <Stack align={"center"}>
                    <Heading
                      align={"center"}
                      className="talk-title-next"
                      marginTop="40px !important"
                    >
                      Developers Nightmares
                    </Heading>
                    <Badge colorScheme={"gray"} fontSize="md" marginBottom={4}>
                      Episodio #3
                    </Badge>
                    <Badge
                      colorScheme={"purple"}
                      fontSize="md"
                      marginBottom={4}
                    >
                      Twitter Spaces
                    </Badge>
                    <Text
                      align={"center"}
                      color="#62676F"
                      marginTop="25px !important"
                    >
                      A veces nuestra profesión puede convertirse en una película de terror. En el episodio 3 de Developer Sessions hablaremos de los proyectos que nos han dado dolores de cabeza, código de espanto, de aquella subida a producción... {" "}<br></br> Contaremos con 
                      {" "} <a
                      href="https://twitter.com/ender3_"
                      target={"_blank"}
                      rel="noreferrer"
                    >
                      Luis Ibáñez
                    </a>
                    ,{" "}
                    <a
                      href="https://twitter.com/nhan_bcn"
                      target={"_blank"}
                      rel="noreferrer"
                    >
                      Ana Carmona
                    </a>
                    ,{" "}
                    <a
                      href="https://twitter.com/javigs00"
                      target={"_blank"}
                      rel="noreferrer"
                    >
                      Javier Gonzalez
                    </a>
                    , y{" "}
                    <a
                      href="https://twitter.com/david_bonilla"
                      target={"_blank"}
                      rel="noreferrer"
                    >
                      David Bonilla
                    </a>
                    </Text>
                  </Stack>
                </Box>
              </Center>
            </a>
          </Stack>
        </VStack>
      </SimpleGrid>
    </>
  );
}
