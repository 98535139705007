import './App.css';
import { Fragment, useEffect } from 'react';
import { Routes, Route } from "react-router-dom";
import { Landing } from './pages/landing';


import ReactGA from 'react-ga4';

ReactGA.initialize("G-0PNSL7MWHN");

export default function App() {
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: document.title });
  }, []);

  return (
    <Fragment>
       <Routes>
        <Route path="/" element={<Landing />} />
      </Routes>
    </Fragment>  
  );
}