import { Fragment } from "react";
import { DeveloperSessionsList } from "../components/developer-sessions-list";
import { Footer } from "../components/footer";
import { Header } from "../components/header";
import { NextEvents } from "../components/next-events";
import { WhoWeAre } from "../components/who-we-are";
import { ChangeDocumentTitle } from "../Utils/Utilities";

export function Landing() {
  ChangeDocumentTitle("Developer Sessions");
  return (
    <Fragment>
      <Header />
      <NextEvents />
      <DeveloperSessionsList />
      <WhoWeAre />
      <Footer />
    </Fragment>
  );
}
